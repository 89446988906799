import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';

import {
    DEFAULT_ENERGY_TYPE,
    DEFAULT_PAGE_ID,
    DEFAULT_SIGN_UP_FLOW,
    ENERGY_TYPES,
    ERRORS,
    PAGE_ID
} from '../../../constants';

import * as API from '../../api';
import * as contentApi from '../../content';
import { isTestEnv } from '../env';
import { buildTariffsPageUrl } from '../url/urlBuilder';

async function getLandingPageDetailsWithContent(identifier = DEFAULT_PAGE_ID) {
    const result = {};

    try {
        result.landingDetails = await API.getLandingDetails(identifier);
    } catch (error) {
        if (!isTestEnv()) {
            console.error(error);
        }

        result.closestProducer = await API.getClosestProducer(identifier);
        if (!result.closestProducer) {
            throw error;
        }

        result.landingDetails = await API.getLandingDetails(PAGE_ID.REGIONAL_CAMPAIGN);
        result.isRegionalCampaignPage = true;
    }

    result.landingContent = await contentApi.getLandingContent(result.landingDetails.contentfulToken);
    result.defaultFooterContent = await contentApi.getFooterContent();

    return result;
}

/**
 * Should get and return common pages content
 * @param {Object} params - Query parameters data
 * @returns {Promise} - { ...content, landingContent, landingDetails, isPageInIframe }
 */
async function getCommonPageContent({ solo, iframe, identifier = DEFAULT_PAGE_ID } = {}) {
    const content = {};

    const { landingDetails, closestProducer, isRegionalCampaignPage, landingContent, defaultFooterContent } =
        await CommonPageHelpers.getLandingPageDetailsWithContent(identifier);

    if (closestProducer) {
        // TODO: Rename producer to closestProducer in code and contentful regional_campaign @Anton
        content.producer = closestProducer;
    }

    await CommonPageHelpers.addFeaturedProducersToSectionsData({ producer: content.producer, landingContent });

    return {
        ...content,
        ...landingContent, // FIXME: Use content.landingContent everywhere and then remove ...landingContent @Anton
        landingDetails,
        landingContent,
        isPageInIframe: !!iframe,
        isSoloPage: !!solo,
        defaultFooterContent,
        isRegionalCampaignPage
    };
}

// TODO: Cover by tests (R)
// TODO: Make the function clean @Anton, Looks like deprecated code, should we remove?
async function addFeaturedProducersToSectionsData({ landingContent, producer }) {
    const featuredProducersSectionIndex = get(landingContent, 'sections', []).findIndex(
        section => get(section, 'sys.contentType.sys.id') === 'featuredProducersSection'
    );
    const featuredProducersSectionConfig = get(landingContent, `sections[${featuredProducersSectionIndex}]`);
    if (featuredProducersSectionConfig) {
        const setClosestProducers = get(featuredProducersSectionConfig, 'fields.setClosestProducers');
        const producerIds = get(landingContent, `sections[${featuredProducersSectionIndex}].fields.producerIds`, []);
        set(
            landingContent,
            `sections[${featuredProducersSectionIndex}].fields.featuredProducers`,
            await contentApi.getFeaturedProducers(
                (setClosestProducers && producer && producer.closestProducersIds) || producerIds
            )
        );
    }
}

/**
 * This common function for get producer prices data and handle errors correctly
 *
 * @param {String} zip - Postcode
 * @param {String} consumption - Consumption value
 * @param {String} cityName - City name
 * @param {String} signupFlow - Sign up flow
 * @param {Object} tariff - Tariff data
 * @param {String} streetName - Street name
 * @param {String} houseNumber - House number
 * @param {Object} content - Object with content data, especially with error messages
 * @param {String} dayConsumption - HT value
 * @param {String} nightConsumption - NT value
 *
 * @returns {Promise} Result of the request error or data
 *
 * @todo: Reuse everywhere to handle errors correctly @Anton
 * @todo: Make the function clear without side effects @Anton
 * @todo: Change the content to tariffCalculationFormContent instead content?.landingContent?.tariffCalculationForm and pass everywhere @Anton
 * @todo: Rename (R)
 * @todo: Reuse everywhere (D)
 *
 */
async function getProductPricesHandler(
    { zip, consumption, cityName, signupFlow, tariff, streetName, houseNumber, dayConsumption, nightConsumption },
    content = {}
) {
    try {
        const { data = {} } = await API.compareProduct(
            zip,
            consumption,
            cityName,
            signupFlow,
            tariff,
            streetName,
            houseNumber,
            undefined,
            undefined,
            undefined,
            undefined,
            dayConsumption,
            nightConsumption
        );

        return { data };
    } catch (error) {
        if (!isTestEnv()) {
            console.error(error);
        }

        const { parameters = {} } = error.extra || {};

        // FIXME: Also we have and can handle specifically in future
        //     "message": "\"campaignIdentifier\" is required",
        //     "code": 13001,
        //     "extra": {
        //         "parameters": {
        //             "campaignIdentifier": "empty"
        //         }
        //     },
        //     "stack": "PowercloudErr...
        if (parameters.postcode) {
            return {
                errors: {
                    compareProductPostcodeErrorMessage: content?.landingContent?.tariffCalculationForm ?
                        content?.landingContent?.tariffCalculationForm?.fields?.compareProductError :
                        ERRORS.CALCULATE_TARIFF,
                    compareProductServerErrorDetails: error
                }
            };
        }

        if (parameters.usage) {
            // FIXME: After we have removed consumption validation from our BE, now we have only PC validation for consumption, and it has the usage property in the parameters, but it can be wrong in some cases.
            //     "message": "Tariff not available for this consumption in this ZIP code.",
            //     "code": 13001,
            //     "extra": {
            //         "parameters": {
            //             "cityId": 2147,
            //             "providerId": 222970,
            //             "productId": 6218308334,
            //             "variant": 1,
            //             "usage": 16,
            //             "business": false,
            //             "modules": "custom",
            //             "customConfig": "[{\"customIds\":[],\"importsIds\":[],\"excludeIds\":[]}]",
            //             "productConfig": "[]",
            //             "campaignIdentifier": "STROMEE0002",
            //             "fixedConfig": "{}",
            //             "tariffHost": "https://app.powercloud.de",
            //             "savingsMode": "ALL"
            //         }
            //     },
            //     "stack": "PowercloudError: Tariff...

            return {
                errors: {
                    consumptionNoTariffErrorMessage: content?.landingContent?.tariffCalculationForm ?
                        content?.landingContent?.tariffCalculationForm?.fields?.consumptionNoTariffError :
                        ERRORS.CONSUMPTION_NO_TARIFF,
                    compareProductServerErrorDetails: error
                }
            };
        }

        return {
            errors: { commonServerErrorDetails: error, compareProductServerErrorDetails: error }
        };
    }
}

/**
 * Custom getInitialPageProps
 * @param query
 * @param asPath
 * @param res
 * @param getContent - Should get and return common pages content
 * @param getData - This function to get data from BE
 * @returns {Promise}
 */
async function getInitialPagePropsCustom({ query, asPath, res }, getContent = () => ({}), getData = () => ({})) {
    const {
        zip,
        consumption,
        signupFlow = DEFAULT_SIGN_UP_FLOW,
        isEcoenergy,
        energyType = DEFAULT_ENERGY_TYPE,
        streetName,
        houseNumber,
        cityName,
        tariffId,
        HT: dayConsumption,
        NT: nightConsumption
    } = query;

    // TODO: Need to get asPath only for pages where it is used
    const state = { result: { query, asPath, isEcoenergy: !!isEcoenergy } };

    try {
        state.result.content = await getContent(query);
    } catch (error) {
        if (!isTestEnv()) {
            console.error(error);
        }
        res.writeHead(302, { Location: `/${PAGE_ID.NOT_FOUND}` });
        return res.end();
    }

    // FIXME: Move to adapter (R)
    const tariffsPageUrl = state?.result?.content?.landingContent?.tariffsPageUrl;
    const isProducersRelatedPage = // FIXME: Move to function and reuse (R)
        typeof tariffsPageUrl === 'string' ? tariffsPageUrl.includes(`/${PAGE_ID.PRODUCERS}`) : false;

    const isTimeDependingConsumption = dayConsumption && nightConsumption;

    const preparedConsumption = isTimeDependingConsumption ? { HT: dayConsumption, NT: nightConsumption } : consumption;

    // We should call the compare product endpoint only when we go to the producers page, to validate the parameters on the landing page.
    const isShouldGetProductPrices =
        !!(zip && preparedConsumption && isProducersRelatedPage) && energyType !== ENERGY_TYPES.GAS;

    if (isShouldGetProductPrices) {
        const productDataResult = await CommonPageHelpers.getProductPricesHandler(
            {
                zip,
                consumption,
                cityName,
                signupFlow,
                tariff: { id: tariffId, energyType },
                streetName,
                houseNumber,
                dayConsumption,
                nightConsumption
            },
            state?.result?.content
        );

        if (productDataResult?.errors) {
            return { ...state.result, ...productDataResult.errors };
        }

        // FIXME: Do not need destructure "...productDataResult.data" result of the compareProduct to the page props, better to use namespace productData @Anton
        state.result = { ...state.result, ...productDataResult.data, productData: productDataResult.data };
    }

    try {
        const data = await getData(state.result);

        const isLandingPage = isEmpty(data);
        if (isLandingPage && zip && preparedConsumption) {
            const nextPageUrl = buildTariffsPageUrl({
                ...query,
                tariffsPageUrl,
                isRegionalCampaignPage: state.result.content.isRegionalCampaignPage,
                isTimeDependingConsumption
            });
            if (asPath !== nextPageUrl) {
                res.writeHead(302, { Location: nextPageUrl });
                return res.end();
            }
        }

        return { ...state.result, ...data };
    } catch (error) {
        console.log(error);
        return { ...state.result, commonServerErrorDetails: error };
    }
}

const CommonPageHelpers = {
    getLandingPageDetailsWithContent,
    getCommonPageContent,
    addFeaturedProducersToSectionsData,
    getProductPricesHandler,
    getInitialPagePropsCustom
};

export default CommonPageHelpers;
